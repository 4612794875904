<template>
  <div class="app-form">
    <b-tabs>
      <b-tab-item label="Таблица">
        <template v-for="(value, name) in model">
          <div class="field is-horizontal" v-bind:key="name">
            <div class="field-label is-normal">
              <label class="label">{{name}}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input" type="text" :disabled="true" :value="value">
                </div>
              </div>
            </div>
          </div>
        </template>
        <template>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Наименование</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input" type="text" v-model="model.name">
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Категория</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control is-expanded">
                  <div class="select is-fullwidth">
                    <select v-model="model.group_id">
                      <option
                        v-for="option in groups"
                        :value="option.db_id"
                        :key="option.db_id">
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Блюда для выбора</label>
            </div>
            <div class="field-body">
                <b-field grouped group-multiline >
                  <template v-for="dish in model.dishes">
                    <div class="control" v-bind:key="dish.db_id">
                      <b-tooltip label="Блюдо для выбора" position="is-top">
                        <b-tag type="is-primary" closable @close="delDish(dish.id)">
                          {{dish.name}} {{dish.price/100}} ₽
                        </b-tag>
                      </b-tooltip>
                    </div>
                  </template>
                </b-field>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Поиск блюда для выбора</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <b-autocomplete
                      :data="data_dishes"
                      placeholder="Введите название блюда"
                      field="product_name"
                      v-model="search_dish"
                      icon="search"
                      is-expanded
                      :loading="isFetchingDish"
                      @typing="getAsyncDishes"
                      @select="option => AddDish(option)">

                      <template slot-scope="props">
                        <div class="media">
                          <div class="media-content">
                            {{ props.option.name }}
                            <br>
                            <small>
                              {{ props.option.price}}
                            </small>
                          </div>
                        </div>
                      </template>
                  </b-autocomplete>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Удален</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control is-expanded">
                <div class="select is-fullwidth">
                  <select expanded v-model="model.is_deleted">
                    <option
                      v-for="option in state_yesno"
                      :value="option.id"
                      :key="option.id">
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr>
          <p class="subtitle is-3 has-text-centered">Управление описанием номенклатуры в продукте</p>
        <hr>

        <template v-for="(dish, key) in model.dishes">
          <div v-bind:key="key">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Наименование</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input" type="text" v-model="dish.name" disabled="">
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Вес, кг</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input" type="text" v-model="dish.weight">
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Описание</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input" type="text" v-model="dish.description">
                  </div>
                </div>
              </div>
            </div>
            <hr>
          </div>
        </template>

        <template v-if="image">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Изображение для блюда основное</label>
            </div>
            <div class="field-body">
              <img :src="getImgUrl(image)">
            </div>
            <p class="control">
              <a href="#" class="button is-danger" @click="delImage()">X</a>
            </p>
          </div>
        </template>
        <template v-else="">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Изображение для блюда основное</label>
            </div>
            <div class="field-body">
              <b-field class="file">
                <b-upload v-model="file" @input="pushName" name="file">
                  <a class="button is-primary">
                    <b-icon icon="upload"></b-icon>
                    <span>Выберите файл</span>
                  </a>
                </b-upload>
                <span class="file-name" v-if="file">
                  {{ file.name }}
                </span>
              </b-field>
            </div>
          </div>
        </template>

        <template v-if="image_main">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Изображение для блюда дополнительное</label>
            </div>
            <div class="field-body">
              <img :src="getImgUrlMain(image_main)">
            </div>
            <p class="control">
              <a href="#" class="button is-danger" @click="delImageMain()">X</a>
            </p>
          </div>
        </template>
        <template v-else="">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Изображение для блюда дополнительное</label>
            </div>
            <div class="field-body">
              <b-field class="file">
                <b-upload v-model="file_main" @input="pushNameMain" name="file">
                  <a class="button is-primary">
                    <b-icon icon="upload"></b-icon>
                    <span>Выберите файл</span>
                  </a>
                </b-upload>
                <span class="file-name" v-if="file_main">
                  {{ file_main.name }}
                </span>
              </b-field>
            </div>
          </div>
        </template>
        <div class="field is-horizontal">
          <div class="field-label">
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <button class="button is-primary" @click="doUpdate()">
                Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="JSON">
        <div class="app-json">
          <vue-json-pretty
            :path="'res'"
            :data="model">
          </vue-json-pretty>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>
<script>
import debounce from 'lodash/debounce'
import {
  getGroups
} from '@/api/group'
import {
  getProduct,
  updateProduct
} from '@/api/product'
import {
  searchDish
} from '@/api/search'
import {
  state_yesno,
  state_show
} from '@/utils'
import {
  uploadFile,
  delFile
} from '@/api/file'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
export default {
  name: 'DishForm',
  components: {
    VueJsonPretty
  },
  props: {
    //
  },
  data: function() {
    const data_dishes = []

    const groups = []

    return {
      state_yesno: state_yesno,
      state_show: state_show,
      groups: groups,

      model: {
        dishes: []
      },

      data_dishes: data_dishes,
      isFetchingDish: false,
      search_dish: '',

      // file
      image: null,
      file: null,
      file_name: undefined,

      // main file
      image_main: null,
      file_main: null,
      file_name_main: undefined,
    }
  },
  created: function() {
    //
  },
  computed: {
    updates_for_dishes () {
      let updates = []

      this.model.dishes.map(item => {
          
          const data = {
            db_id: item.db_id,
            weight: item.weight,
            description: item.description
          }

          updates.push(data)
      })

      return updates
    },
  },
  beforeMount: function() {
    //
  },
  mounted: function() {
    let $this = this

    getProduct(this.$route.params.id, this.$store.state.jwt_user)
      .then(response => {
        let data = response.data.data;
        
        for(let propertyName in data) {
          if(propertyName != "main_file" && propertyName != "file"){
              $this.model[propertyName] = data[propertyName]
          }
          else{
            if(propertyName == "main_file"){
              if (data[propertyName] != null){
                $this.image_main = data[propertyName].file_name
              }
            }
            if(propertyName == "file"){
              if (data[propertyName] != null){
                $this.image = data[propertyName].file_name
              }
            }
          }
        }
        // let data = response.data;

        // let result = data.data
        // this.model = result

      })
      .catch(
        this.model = {
          dishes: []
        }
      )

    getGroups(this.$store.state.jwt_user)
      .then(response => {
        let data = response.data;

        data.data.forEach((item) => {
          this.groups.push(item)
        })
      })
      .catch(
        this.groups = []
      )
  },
  methods: {
    // Del dish
    delDish(del_dish) {

      var ids = this.model.dishes.map(function(o) {
        return o.id;
      })

      var index = ids.indexOf(del_dish.id);

      this.model.dishes.splice(index, 1);
    },

    // Search dish
    getAsyncDishes: debounce(function() {
      if (!this.model.group_id){
        this.data_dishes = []
        return
      }

      if (!this.search_dish.length) {
        this.data_dishes = []
        return
      }

      this.isFetchingDish = true

      searchDish(this.search_dish, this.model.group_id, this.$store.state.jwt_user)
        .then(response => {
          let data = response.data.data;
          if (data.length) {
            this.data_dishes = []
            data.forEach((item) => this.data_dishes.push(item))
          } else {
            this.data_dishes = []
          }
        })
        .catch(
          this.data_dishes = []
        )

      this.isFetchingDish = false

    }, 500),

    // Add dish
    AddDish(added_dish) {
      let check = this.model.dishes.filter(function(item) {
        return item.id == added_dish.id
      })

      if (!check.length) {
        this.model.dishes.push(added_dish)
      } else {
        //
      }
    },

    // Image
    pushName(file) {
      let $this = this
      if (file) {
        $this.file_name = file.name
        $this.doUpload()
      }
    },
    doUpload: function() {
      let $this = this
      let formData = new FormData();

      if ($this.file) {
        formData.append('file', $this.file)
        if ($this.file_name) {
          formData.append('name', $this.file_name)
        }
        uploadFile(formData, this.$store.state.jwt_user)
          .then(response => {
            let data = response.data.data
            this.image = data.file_name
            this.model.file_id = data.id
            this.file = null
            this.file_name = null
          })
      }
    },
    getImgUrl(image) {
      return `/upload/${image}`
    },
    delImage() {
      this.$buefy.dialog.confirm({
        message: 'Вы уверены что хотите удалить изображение',
        cancelText: 'Нет',
        confirmText: 'Да',
        onConfirm: () => {
          delFile(this.model.file_id, this.$store.state.jwt_user)
            .then(() => {
              this.model.file_id = null
              this.image = null
            })
        }
      })
    },

    // Main Image
    pushNameMain(file) {
      let $this = this
      if (file) {
        $this.file_name_main = file.name
        $this.doUploadMain()
      }
    },
    doUploadMain: function() {
      let $this = this
      let formData = new FormData();

      if ($this.file_main) {
        formData.append('file', $this.file_main)
        if ($this.file_name_main) {
          formData.append('name', $this.file_name_main)
        }
        uploadFile(formData, this.$store.state.jwt_user)
          .then(response => {
            let data = response.data.data
            this.image_main = data.file_name
            this.model.main_file_id = data.id
            this.file_main = null
            this.file_name_main = null
          })
      }
    },
    getImgUrlMain(image) {
      return `/upload/${image}`
    },
    delImageMain() {
      this.$buefy.dialog.confirm({
        message: 'Вы уверены что хотите удалить изображение',
        cancelText: 'Нет',
        confirmText: 'Да',
        onConfirm: () => {
          delFile(this.model.main_file_id, this.$store.state.jwt_user)
            .then(() => {
              this.model.main_file_id = null
              this.image_main = null
            })
        }
      })
    },


    doUpdate () {
      this.$buefy.dialog.confirm({
        message: "Вы уверены?",
        cancelText: "Нет",
        confirmText: "Да",
        onConfirm: () => {

          let payload = this.model

          payload["updates_for_dishes"] = this.updates_for_dishes

          if (payload["dishes"].length == 0){
            return
          }
          
          updateProduct(payload, this.$store.state.jwt_user).then(() =>
            {
              console.log(payload)
              this.$router.push({ name: 'a-products', params: {} })
            }
          )        

        }
      })
    },
  },
}
</script>
<style scoped>
  .app-form {
    text-align: left;
  }
</style>
<style></style>